var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row"},[_c('showHeader',{staticClass:"col-12 mt-3",attrs:{"icon":'store-alt',"title":_vm.item.name,"subData":[
                    {title: _vm.$t('stores.form.theState'), value: _vm.item.country},
                    {title: _vm.$t('stores.form.city'), value: _vm.item.city} ],"buttons":[
                    {title: _vm.$t('stores.show.modulation'), icon: 'edit', link: _vm.path+'/'+_vm.$route.params.id+'/edit', role: _vm.$user.admin || _vm.$user.role.stores_edit},
                    {title: _vm.$t('stores.show.InventoryOfTheStore'), icon: 'file-chart-pie', link: '/warehouseInventory?store_id=' + _vm.$route.params.id, role: _vm.$user.admin || _vm.$user.role.reports_show} ]}}),_c('showCard',{staticClass:"col-12",attrs:{"title":_vm.$t('stores.show.StoreAddress'),"cloumns":{
                       firstCloum: [
                         {icon: 'map-marker-alt', title: _vm.$t('stores.show.Address'), value: _vm.item.address1},
                         {icon: 'location-arrow', title: _vm.$t('stores.show.TheSide'), value: _vm.item.entity},
                         {icon: 'map-marked', title: _vm.$t('stores.show.ZipCode'), value: _vm.item.zip} ],
                       scondCloum: [
                         {icon: 'map-marker-alt', title: _vm.$t('stores.show.TheSequel'), value: _vm.item.address2},
                         {icon: 'city', title: _vm.$t('stores.show.City'), value: _vm.item.city},
                         {icon: 'globe-americas', title: _vm.$t('stores.show.State'), value: _vm.item.country} ]
                     }}}),(_vm.$site.invoices_allow && (_vm.$user.admin || _vm.$user.role.invoices_show))?_c('invoices',{staticClass:"col-12"}):_vm._e(),(_vm.$site.bills_allow && (_vm.$user.admin || _vm.$user.role.bills_show))?_c('bills',{staticClass:"col-12"}):_vm._e(),(_vm.$site.saleReturns_allow && (_vm.$user.admin || _vm.$user.role.sale_returns_show))?_c('salesReturns',{staticClass:"col-12"}):_vm._e(),(_vm.$site.purchaseReturns_allow && (_vm.$user.admin || _vm.$user.role.purchase_returns_show))?_c('purchaseReturns',{staticClass:"col-12"}):_vm._e(),(_vm.$site.storesDeposits_allow && (_vm.$user.admin || _vm.$user.role.stores_deposits_show))?_c('storesDeposits',{staticClass:"col-12"}):_vm._e(),(_vm.$site.storesWithdrawals_allow && (_vm.$user.admin || _vm.$user.role.stores_withdrawals_show))?_c('storesWithdrawals',{staticClass:"col-12"}):_vm._e(),(_vm.$site.storesTransfers_allow && (_vm.$user.admin || _vm.$user.role.stores_transfers_show))?_c('fromStoresTransfers',{staticClass:"col-12"}):_vm._e(),(_vm.$site.storesTransfers_allow && (_vm.$user.admin || _vm.$user.role.stores_transfers_show))?_c('toStoresTransfers',{staticClass:"col-12"}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }