var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_vm._v(" "+_vm._s(_vm.$t('components.stores.salesInvoices')))]),( _vm.$site.invoices_allow && (_vm.$user.admin || _vm.$user.role.invoices_add))?_c('router-link',{staticClass:"btn btn-sm btn-primary ",attrs:{"to":'/invoices/create?store_id=' + _vm.$route.params.id}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t('components.stores.addition'))+" ")]):_vm._e()],1),_c('indexTable',{attrs:{"searchText":_vm.$t('invoices.searchForAnInvoice'),"withoutCard":true,"emptyTableText":_vm.$t('invoices.thereAreNoBills'),"filter":{store_id: _vm.$route.params.id},"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"statusSearch":true,"statusValues":[
            { title: _vm.$t('quotations.situation'), value: '' },
            { title: _vm.$t('quotations.all'), value: '' },
            { title: _vm.$t('invoices.draft'), value: 0 },
            { title: _vm.$t('invoices.sent'), value: 1 },
            { title: _vm.$t('invoices.seen'), value: 2 },
            { title: _vm.$t('invoices.driven'), value: 3 },
            { title: _vm.$t('invoices.canceled'), value: 4 } ],"cloumns":[
            {
              column: 'code',
              title: _vm.$t('invoices.invoice'),
              type: 'mainLink',
              sort: true,
            },
            {
              column: 'date',
              title: _vm.$t('invoices.theDateOfTheInvoice'),
              type: 'text',
              sort: true,
            },
            {
              column: 'client_type',
              title: _vm.$t('invoices.customerName'),
              type: 'clintLink',
              sort: false,
            },
            {
              column: 'total',
              title: _vm.$t('invoices.cost'),
              type: 'text',
              sort: true
            },
            {
              column: 'rest',
              title: _vm.$t('invoices.balance'),
              type: 'text',
              sort: true
            },
            {
              column: 'status',
              title: _vm.$t('invoices.situation'),
              type: 'status',
              sort: true,
              values: [
                { title: _vm.$t('invoices.draft'), value: 0, color: 'dark' },
                { title: _vm.$t('invoices.sent'), value: 1, color: 'primary' },
                { title: _vm.$t('invoices.seen'), value: 2, color: 'primary' },
                {
                  title: _vm.$t('invoices.driven'),
                  value: 3,
                  color: 'success',
                },
                {
                  title: _vm.$t('invoices.canceled'),
                  value: 4,
                  color: 'danger',
                } ],
            },
            {
              column: 'options',
              title: _vm.$t('invoices.settings'),
              type: 'options',
              options: [
                { name: 'show' },
                {
                  name: 'edit',
                  role: _vm.$user.admin || _vm.$user.role.invoices_edit,
                },
                { name: 'outLink', role: true },
                { name: 'printInvoice', role: true },
                { name: 'download', role: true },
                {
                  name: 'delete',
                  role: _vm.$user.admin || _vm.$user.role.invoices_delete,
                } ],
            } ],"deleteText":{
            attention: _vm.$t('allerts.Attention'),
            areYouReallySureToDeleteTheItem:
              _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
            close: _vm.$t('allerts.close'),
            confirm: _vm.$t('allerts.confirm'),
            loading: _vm.$t('allerts.loading'),
          }}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }