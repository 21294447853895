var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('h4',{staticClass:"card-header-title"},[_vm._v(_vm._s(_vm.$t('components.stores.transfersFromWarehouse')))]),( _vm.$site.salesPayments_allow && (_vm.$user.admin || _vm.$user.role.sales_payments_add))?_c('router-link',{staticClass:"btn btn-sm btn-primary",attrs:{"to":'/storesTransfers/create?store_id=' + _vm.$route.params.id}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t('components.stores.addition'))+" ")]):_vm._e()],1),_c('indexTable',{attrs:{"searchText":_vm.$t('storesTransfers.searchForAnStoresTransfersOperations'),"emptyTableText":_vm.$t('storesTransfers.thereAreNostoresTransfersOperations'),"filter":{store_id: _vm.$route.params.id},"withoutCard":true,"emptyTableSubText":_vm.$t('allerts.TryAddingSomeItemsToTheTableAndTryAgain'),"cloumns":[
                              {column: 'code',    title: _vm.$t('storesTransfers.operation') ,type: 'mainLink' , sort: true },
                              {column: 'from_store_id',    title: _vm.$t('storesTransfers.fromStoreName'), element:'fromStore', type: 'link', to:'store', sort: true, link: true},
                              {column: 'to_store_id',    title: _vm.$t('storesTransfers.toStoreName'), element:'toStore', type: 'link', to:'store', sort: true, link: true},
                              {column: 'date',    title: _vm.$t('storesTransfers.theDateOfTheOperation') ,type: 'text' , sort: true },
                              {column: 'description',    title: _vm.$t('storesTransfers.description') ,type: 'text' , sort: true },
                              {column: 'options', title: _vm.$t('storesTransfers.settings'), type: 'options', options: [
                                  {name: 'show'},
                                  {name: 'edit',   role: _vm.$user.admin || _vm.$user.role.stores_transfers_edit},
                                  {name: 'printStoresTransfer',   role: true},
                                  {name: 'delete', role: _vm.$user.admin || _vm.$user.role.stores_transfers_delete} ]}
                            ],"deleteText":{
                             attention: _vm.$t('allerts.Attention'),
                             areYouReallySureToDeleteTheItem: _vm.$t('allerts.areYouReallySureToDeleteTheItem'),
                             close: _vm.$t('allerts.close'),
                             confirm: _vm.$t('allerts.confirm'),
                             loading: _vm.$t('allerts.loading'),
                            }}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }